import Timezones from '../content/Timezones.json';

export const LANGUAGES = {
  EN: { code: 'en', label: 'English' },
  FR: { code: 'fr', label: 'Français' },
};

export const PAYMENT_STATUS = {
  PAID: 'PAID',
  STAGED: 'STAGED',
  EXCEPTION: 'EXCEPTION',
  FAILED: 'FAILED',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
  PROCESSING: 'PROCESSING',
  EXPIRED: 'EXPIRED',
  REFUNDED: 'REFUNDED',
  REFUND_PENDING: 'REFUND_PENDING',
  REFUND_MULTIPLE: 'REFUND_MULTIPLE',
  REFUND_CANCELLED: 'REFUND_CANCELLED',
  TEST_MODE: 'TEST_MODE',
  UNKNOWN: 'UNKNOWN',
};

export const PAYOUT_STATUS = {
  NET_ZERO: 'NET_ZERO',
  PENDING: 'PENDING',
  SENT: 'SENT',
  FAILED: 'FAILED',
  REJECTED: 'REJECTED',
  COMPLETED: 'COMPLETED',
  UNKNOWN: 'UNKNOWN',
};

export const TRANSACTION_TYPE = {
  PAD_CREDIT: 'PAD_CREDIT',
  PURCHASE: 'PURCHASE',
  REFUND: 'REFUND',
};

export const PAYMENT_TYPE = {
  CASH: 'A',
  DEBIT_CARD: 'D',
  CASH_AND_DEBIT_CARD: 'Q',
};

export const TIMEZONE_CODES = Timezones.reduce((acc, tz) => {
  acc[tz.Code] = Number.parseFloat(tz.Value);
  return acc;
}, {});

export const DIRECTION = {
  ASCENDING: 'ASC',
  DESCENDING: 'DESC',
};

// Constant defining types of reports
// Used as event props for the Amplitude events
// Also used to determine the currently selected report, values are lowercased for query parameters
// Do not change the values unless necessary, as they are used as identifiers
export const REPORTS = {
  PAYMENTS: 'Payments',
  RECONCILIATION: 'Reconciliation',
  PAYOUTS: 'Payouts',
};

export const REPORTS_API = {
  GET_PAYMENTS_REPORT: 'Report/GetPaymentsReport',
  GET_LOADHUB_PAYMENTS_REPORT: 'Report/GetLoadhubPaymentsReport',
  GET_RECONCILIATION_REPORT: 'Report/GetReconciliationReport',
  GET_LOADHUB_RECONCILIATION_REPORT: 'Report/GetLoadhubReconciliationReport',
  GET_PAYOUTS_REPORT: 'Report/GetPayoutsReport',
  GET_PAYMENT_DETAILS: 'Report/GetPaymentDetails',
  REQUEST_REFUND: 'RequestRefund',
};

export const AUTH_STEP = {
  LOGIN: 'Login',
  REGISTER: 'Register',
  CONFIRM_EMAIL: 'Confirm Email',
  FORGOT_PASSWORD: 'Forgot Password',
  VERIFY_EMAIL: 'Verify Email',
};

export const AUTH_API = {
  INITIATE_AUTH: 'InitateAuth',
  LOGIN: 'Authenticate',
  REGISTER: 'SignUp',
  CONFIRM_EMAIL: 'ConfirmSignUp',
  RESEND_CONFIRM_EMAIL: 'ResendConfirmationCode',
  FORGOT_PASSWORD: 'ForgotPassword',
  CONFIRM_FORGOT_PASSWORD: 'ConfirmForgotPassword',
  OAUTH_INITIATE: 'Xero/OAuthInitiate',
};

export const OAUTH_LINKS = {
  Xero: `${process.env.NEXT_PUBLIC_API_URI}${AUTH_API.OAUTH_INITIATE}`,
  Shopify: process.env.NEXT_PUBLIC_SHOPIFY_INSTALLATION_LINK,
};

export const PARTNER_API = {
  UPDATE_PARTNER: 'UpdatePartner',
  UPDATE_SETTINGS: 'Xero/UpdateSettings',
  GET_PARTNERS: 'GetPartners',
  DISCONNECT_PARTNERS: 'Xero/Disconnect',
  MAINTENANCE_MODE: 'MaintenanceMode',
  SHOPIFY_ACTIVATE: 'Shopify/Activate',
};

export const SUCCESS_ALERT = {
  UPDATE_PARTNER: 'UpdatePartner',
  UPDATE_SETTINGS: 'UpdateSettings',
  ACTIVATE: 'Activate',
};

export const PARTNER_DETAILS_STEP = {
  BUSINESS_INFO: 'BusinessInformation',
  CONTACT_INFO: 'ContactInformation',
  PRICING: 'Pricing',
  AGREEMENT: 'Agreement',
  PREFERENCES: 'Preferences',
};

export const EMPTY_DIRECT_PARTNER = {
  CreatedBy: '',
  Status: '',
  ActivationDate: '',
  SubmittedDate: '',
  StartedReviewDate: '',
  DecisionDate: '',
  InactiveDate: '',
  ApprovedDate: '',
  ActivatedOnPlatform: '',
  PlatformPartnerName: '',
  ClearingAccount: {
    Name: '',
    ID: '',
  },
  FeeAccount: {
    Name: '',
    ID: '',
  },
  PayoutAccount: {
    Name: '',
    ID: '',
  },
  XeroData: {
    PayoutAccounts: [],
    BrandingThemes: [],
  },
  Platform_Ref2: '',
  ActivationUrl: '',
  PricingPlanDisplayName: '',
  VoidChequeVerified: false,
  BankName: '',
  Language: '',
  IntegrationPlatform: 'Hosted',
  PlatformPartner_Seq: '',
  Partner_Seq: '',
  LegalName: '',
  BinNumber: '',
  GstHstNumber: '',
  DateIncorporated: '',
  LegalStructure: '',
  BusinessWebsiteUrl: '',
  LeadSource: '',
  Street: '',
  UnitSuite: '',
  City: '',
  Province: '',
  PostalCode: '',
  IndustryCategory: '',
  IndustrySubCategory: '',
  IsHighRisk: false,
  CountriesOfBusiness: [],
  AverageTransactionValue: '',
  MonthlySalesValue: '',
  MonthlySalesVolume: '',
  RegulatedEntity: '',
  PrimaryIndividual: {
    FullName: '',
    PercentOwnership: '',
    DateOfBirth: '',
    Phone: '',
    Email: '',
    Address: {
      Unit: '',
      Street: '',
      City: '',
      Province: '',
      PostalCode: '',
    },
    PublicInterest: '',
    PartnerIndividual_Seq: '',
  },
  BusinessOwnershipIndividuals: [],
  DisplayNameEn: '',
  DisplayNameFr: '',
  OtherEmail: '',
  AgreementSignedDate: '',
  BankTransitNumber: '',
  BankBranch: '',
  AccountNumber: '',
  VoidCheque: '',
  PricingPlan: '',
};

export const HARDCODED_PARTNERS =
  [
    {
      CreatedBy: null,
      Status: 'A',
      ActivationDate: '2022-09-12 16:56:13',
      SubmittedDate: '2022-09-29 01:01:01',
      StartedReviewDate: '2022-09-29 03:03:03',
      DecisionDate: '2022-09-29 02:02:02',
      InactiveDate: '2022-09-29 04:04:04',
      ActivatedOnPlatform: true,
      PaymentAccountName: 'DirectPay Clearing Account',
      FeeAccountName: 'DirectPay Payment Fee',
      Language: '',
      IntegrationPlatform: null,
      PlatformPartner_Seq: '',
      PlatformPartnerName: 'Saturn Company',
      Partner_Seq: '',
      LegalName: 'Saturn Company',
      BinNumber: '',
      GstHstNumber: '',
      LegalStructure: '',
      DateIncorporated: '2022-09-29 05:05:05',
      BusinessWebsiteUrl: 'Business Website Url:',
      Street: '',
      UnitSuite: '',
      City: '',
      Province: '',
      PostalCode: '',
      IndustryCategory: 'Industry Category:',
      IndustrySubCategory: 'Industry Sub Category:',
      IsHighRisk: false,
      CountriesOfBusiness: [
        'Countries Of Busi',
      ],
      AverageTransactionValue: '1',
      MonthlySalesValue: '2',
      MonthlySalesVolume: '3',
      RegulatedEntity: '',
      SupportName: '',
      SupportPhone: '',
      OtherEmail: 'psidevstar@gmail.com',
      SupportDateOfBirth: '9/29/1980 12:00:00 AM',
      SupportAddress: {
        Unit: null,
        Street: '130 Brew St',
        City: 'Port Moody',
        Province: 'BC',
        PostalCode: 'V3H 0E3',
      },
      BusinessOwnershipIndividuals: [
        {
          FullName: 'John Smith',
          PercentOwnership: '100',
          DateOfBirth: '1981-01-25',
          Address: {
            Unit: null,
            Street: '130 Brew St',
            City: 'Port Moody',
            Province: 'BC',
            PostalCode: 'V3H 0E3',
          },
          PublicInterest: true,
        },
      ],
      DisplayNameEn: 'Saturn Company EN',
      DisplayNameFr: 'Saturn Company FR',
      AgreementSignedDate: '',
      PayoutAccount: {
        Name: 'Business Account',
        ID: 'f63e2d2c-923e-47ee-9bc1-78a40a914856',
      },
      ClearingAccount: {
        Name: 'DirectPay Payment Clearing',
        AccountID: 'a6608de6-46fb-4719-92a7-18bd462dbfe5',
      },
      FeeAccount: {
        Name: 'DirectPay Payment Fee',
        AccountID: 'a6608de6-46fb-4719-92a7-18bd462dbfe5',
      },
      BankName: '',
      BankBranch: '',
      BankTransitNumber: '',
      AccountNumber: '**000',
      XeroData: {
        PayoutAccounts: [
          {
            Name: 'Business Account',
            ID: 'f63e2d2c-923e-47ee-9bc1-78a40a914856',
          },
        ],
        BrandingThemes: [
          {
            Name: 'Standard',
            ID: 'c4521403-696d-4c2c-b800-efc0c3ebb5a8',
            DirectPayEnabled: false,
          },
          {
            Name: 'Orange Theme',
            ID: '146576fb-9983-4b0d-8e78-e42b79409b9a',
            DirectPayEnabled: false,
          },
          {
            Name: 'Red Theme',
            ID: '481c61c7-f4d6-46fc-b139-609764ffde31',
            DirectPayEnabled: false,
          },
        ],
      },
      PrimaryIndividual: {
        FullName: 'Charlie Doe',
        PercentOwnership: '25',
        DateOfBirth: '1987-08-08',
        Address: {
          Unit: '11',
          Street: '11111 Test Road, 11',
          City: 'Richmond',
          Province: 'BC',
          PostalCode: 'V7V 2H9',
        },
        PublicInterest: 'false',
        PartnerIndividual_Seq: '415',
        Phone: '778-888-9999',
        Email: 'charliedoe@gmail.com',
      },
      Platform_Ref2: 'saturn-company.ca', // for Shopify
      ActivationUrl: 'saturn-company.myshopify.com',
      PricingPlanDisplayName: 'Standard',
    },
    {
      CreatedBy: null,
      Status: 'N',
      ActivationDate: '',
      SubmittedDate: '',
      StartedReviewDate: '',
      DecisionDate: '',
      InactiveDate: '',
      ActivatedOnPlatform: false,
      PaymentAccountName: null,
      FeeAccountName: null,
      Language: 'en',
      IntegrationPlatform: null,
      PlatformPartner_Seq: '',
      Partner_Seq: '',
      LegalName: 'Earth Company',
      PlatformPartnerName: 'Earth Company',
      BinNumber: '',
      GstHstNumber: '',
      LegalStructure: '',
      DateIncorporated: '',
      BusinessWebsiteUrl: '',
      Street: '',
      UnitSuite: '',
      PayoutAccount: {
        Name: 'Business Account',
        ID: 'f63e2d2c-923e-47ee-9bc1-78a40a914856',
      },
      ClearingAccount: {
        Name: 'DirectPay Payment Clearing',
        AccountID: 'a6608de6-46fb-4719-92a7-18bd462dbfe5',
      },
      FeeAccount: {
        Name: 'DirectPay Payment Fee',
        AccountID: 'a6608de6-46fb-4719-92a7-18bd462dbfe5',
      },
      City: '',
      Province: '',
      PostalCode: '',
      IndustryCategory: '',
      IndustrySubCategory: '',
      IsHighRisk: false,
      CountriesOfBusiness: [
        '',
      ],
      AverageTransactionValue: '',
      MonthlySalesValue: '',
      MonthlySalesVolume: '',
      RegulatedEntity: '',
      SupportName: '',
      SupportPhone: '',
      OtherEmail: '',
      SupportDateOfBirth: '',
      SupportAddress: null,
      BusinessOwnershipIndividuals: null,
      DisplayNameEn: 'Earth Company EN',
      DisplayNameFr: 'Earth Company FR',
      AgreementSignedDate: null,
      BankName: '',
      BankBranch: '',
      BankTransitNumber: '',
      AccountNumber: '',
      XeroData: {
        PayoutAccounts: [
          {
            Name: 'Business Account',
            ID: 'f63e2d2c-923e-47ee-9bc1-78a40a914856',
          },
        ],
        BrandingThemes: [
          {
            Name: 'Standard',
            ID: 'c4521403-696d-4c2c-b800-efc0c3ebb5a8',
            DirectPayEnabled: false,
          },
          {
            Name: 'Orange Theme',
            ID: '146576fb-9983-4b0d-8e78-e42b79409b9a',
            DirectPayEnabled: false,
          },
          {
            Name: 'Red Theme',
            ID: '481c61c7-f4d6-46fc-b139-609764ffde31',
            DirectPayEnabled: false,
          },
        ],
      },
      PrimaryIndividual: {
        FullName: 'Charlie Doe',
        PercentOwnership: '25',
        DateOfBirth: '1987-08-08',
        Address: {
          Unit: '11',
          Street: '11111 Test Road, 11',
          City: 'Richmond',
          Province: 'BC',
          PostalCode: 'V7V 2H9',
        },
        PublicInterest: 'false',
        PartnerIndividual_Seq: '415',
        Phone: '778-888-9999',
        Email: 'charliedoe@gmail.com',
      },
    },
    {
      CreatedBy: null,
      Status: 'D',
      ActivationDate: '',
      SubmittedDate: '',
      StartedReviewDate: '',
      DecisionDate: '',
      InactiveDate: '',
      ActivatedOnPlatform: true,
      PaymentAccountName: null,
      FeeAccountName: null,
      Language: 'en',
      IntegrationPlatform: null,
      PlatformPartner_Seq: '',
      Partner_Seq: '',
      LegalName: 'Mars Company',
      PlatformPartnerName: 'Mars Company',
      BinNumber: '',
      GstHstNumber: '',
      LegalStructure: '',
      DateIncorporated: '',
      BusinessWebsiteUrl: '',
      Street: '',
      UnitSuite: '',
      City: '',
      Province: '',
      PostalCode: '',
      PayoutAccount: {
        Name: 'Business Account',
        ID: 'f63e2d2c-923e-47ee-9bc1-78a40a914856',
      },
      ClearingAccount: {
        Name: 'DirectPay Payment Clearing',
        AccountID: 'a6608de6-46fb-4719-92a7-18bd462dbfe5',
      },
      FeeAccount: {
        Name: 'DirectPay Payment Fee',
        AccountID: 'a6608de6-46fb-4719-92a7-18bd462dbfe5',
      },
      IndustryCategory: '',
      IndustrySubCategory: '',
      IsHighRisk: false,
      CountriesOfBusiness: [
        '',
      ],
      AverageTransactionValue: '',
      MonthlySalesValue: '',
      MonthlySalesVolume: '',
      RegulatedEntity: '',
      SupportName: '',
      SupportPhone: '',
      OtherEmail: '',
      SupportDateOfBirth: '',
      SupportAddress: null,
      BusinessOwnershipIndividuals: null,
      DisplayNameEn: 'Mars Company EN',
      DisplayNameFr: 'Mars Company FR',
      AgreementSignedDate: null,
      BankName: '',
      BankBranch: '',
      BankTransitNumber: '',
      AccountNumber: '',
      XeroData: {
        PayoutAccounts: [
          {
            Name: 'Business Account',
            ID: 'f63e2d2c-923e-47ee-9bc1-78a40a914856',
          },
        ],
        BrandingThemes: [
          {
            Name: 'Standard',
            ID: 'c4521403-696d-4c2c-b800-efc0c3ebb5a8',
            DirectPayEnabled: false,
          },
          {
            Name: 'Orange Theme',
            ID: '146576fb-9983-4b0d-8e78-e42b79409b9a',
            DirectPayEnabled: false,
          },
          {
            Name: 'Red Theme',
            ID: '481c61c7-f4d6-46fc-b139-609764ffde31',
            DirectPayEnabled: false,
          },
        ],
      },
      PrimaryIndividual: {
        FullName: 'Charlie Doe',
        PercentOwnership: '25',
        DateOfBirth: '1987-08-08',
        Address: {
          Unit: '11',
          Street: '11111 Test Road, 11',
          City: 'Richmond',
          Province: 'BC',
          PostalCode: 'V7V 2H9',
        },
        PublicInterest: 'false',
        PartnerIndividual_Seq: '415',
        Phone: '778-888-9999',
        Email: 'charliedoe@gmail.com',
      },
    },
    {
      CreatedBy: null,
      Status: 'S',
      ActivationDate: '',
      SubmittedDate: '',
      StartedReviewDate: '',
      DecisionDate: '',
      InactiveDate: '',
      ActivatedOnPlatform: false,
      PaymentAccountName: null,
      FeeAccountName: null,
      Language: 'en',
      IntegrationPlatform: null,
      PlatformPartner_Seq: '',
      Partner_Seq: '',
      LegalName: 'Moon Company',
      PlatformPartnerName: 'Moon Company',
      BinNumber: '',
      GstHstNumber: '',
      LegalStructure: '',
      DateIncorporated: '',
      BusinessWebsiteUrl: '',
      Street: '',
      UnitSuite: '',
      City: '',
      Province: '',
      PostalCode: '',
      IndustryCategory: '',
      IndustrySubCategory: '',
      IsHighRisk: false,
      CountriesOfBusiness: [
        '',
      ],
      AverageTransactionValue: '',
      MonthlySalesValue: '',
      MonthlySalesVolume: '',
      RegulatedEntity: '',
      PayoutAccount: {
        Name: 'Business Account',
        ID: 'f63e2d2c-923e-47ee-9bc1-78a40a914856',
      },
      ClearingAccount: {
        Name: 'DirectPay Payment Clearing',
        AccountID: 'a6608de6-46fb-4719-92a7-18bd462dbfe5',
      },
      FeeAccount: {
        Name: 'DirectPay Payment Fee',
        AccountID: 'a6608de6-46fb-4719-92a7-18bd462dbfe5',
      },
      SupportName: '',
      SupportPhone: '',
      OtherEmail: '',
      SupportDateOfBirth: '',
      SupportAddress: null,
      BusinessOwnershipIndividuals: null,
      DisplayNameEn: 'Moon Company EN',
      DisplayNameFr: 'Moon Company FR',
      AgreementSignedDate: null,
      BankName: '',
      BankBranch: '',
      BankTransitNumber: '',
      AccountNumber: '',
      XeroData: {
        PayoutAccounts: [
          {
            Name: 'Business Account',
            ID: 'f63e2d2c-923e-47ee-9bc1-78a40a914856',
          },
        ],
        BrandingThemes: [
          {
            Name: 'Standard',
            ID: 'c4521403-696d-4c2c-b800-efc0c3ebb5a8',
            DirectPayEnabled: false,
          },
          {
            Name: 'Orange Theme',
            ID: '146576fb-9983-4b0d-8e78-e42b79409b9a',
            DirectPayEnabled: false,
          },
          {
            Name: 'Red Theme',
            ID: '481c61c7-f4d6-46fc-b139-609764ffde31',
            DirectPayEnabled: false,
          },
        ],
      },
      PrimaryIndividual: {
        FullName: 'Charlie Doe',
        PercentOwnership: '25',
        DateOfBirth: '1987-08-08',
        Address: {
          Unit: '11',
          Street: '11111 Test Road, 11',
          City: 'Richmond',
          Province: 'BC',
          PostalCode: 'V7V 2H9',
        },
        PublicInterest: 'false',
        PartnerIndividual_Seq: '415',
        Phone: '778-888-9999',
        Email: 'charliedoe@gmail.com',
      },
    },
    {
      CreatedBy: null,
      Status: 'P',
      ActivationDate: '2022-10-13 13:12:15',
      SubmittedDate: '',
      StartedReviewDate: '',
      DecisionDate: '',
      InactiveDate: '',
      ActivatedOnPlatform: true,
      PaymentAccountName: 'DirectPay Clearing Account',
      FeeAccountName: 'DirectPay Payment Fee',
      Language: '',
      IntegrationPlatform: null,
      PlatformPartner_Seq: '',
      Partner_Seq: '',
      PlatformPartnerName: 'Zodiac Company',
      LegalName: 'Zodiac Company',
      BinNumber: '',
      GstHstNumber: '',
      LegalStructure: '',
      DateIncorporated: '',
      BusinessWebsiteUrl: '',
      Street: '',
      UnitSuite: '',
      City: '',
      Province: '',
      PostalCode: '',
      IndustryCategory: '',
      IndustrySubCategory: '',
      IsHighRisk: false,
      CountriesOfBusiness: [
        '',
      ],
      AverageTransactionValue: '0',
      MonthlySalesValue: '0',
      MonthlySalesVolume: '0',
      RegulatedEntity: '',
      SupportName: '',
      SupportPhone: '',
      OtherEmail: 'wesleyd@paymentsource.ca',
      SupportDateOfBirth: '',
      SupportAddress: null,
      BusinessOwnershipIndividuals: null,
      DisplayNameEn: 'Zodiac Company EN',
      DisplayNameFr: 'Zodiac Company FR',
      AgreementSignedDate: '',
      PayoutAccount: {
        Name: 'Business Account',
        ID: 'f63e2d2c-923e-47ee-9bc1-78a40a914856',
      },
      ClearingAccount: {
        Name: 'DirectPay Payment Clearing',
        AccountID: 'a6608de6-46fb-4719-92a7-18bd462dbfe5',
      },
      FeeAccount: {
        Name: 'DirectPay Payment Fee',
        AccountID: 'a6608de6-46fb-4719-92a7-18bd462dbfe5',
      },
      BankName: '',
      BankBranch: '',
      BankTransitNumber: '',
      AccountNumber: '',
      XeroData: {
        PayoutAccounts: [
          {
            Name: 'Business Account',
            ID: 'f63e2d2c-923e-47ee-9bc1-78a40a914856',
          },
        ],
        BrandingThemes: [
          {
            Name: 'Standard',
            ID: 'c4521403-696d-4c2c-b800-efc0c3ebb5a8',
            DirectPayEnabled: true,
          },
          {
            Name: 'Orange Theme',
            ID: '146576fb-9983-4b0d-8e78-e42b79409b9a',
            DirectPayEnabled: false,
          },
          {
            Name: 'Red Theme',
            ID: '481c61c7-f4d6-46fc-b139-609764ffde31',
            DirectPayEnabled: false,
          },
        ],
      },
      PrimaryIndividual: {
        FullName: 'Charlie Doe',
        PercentOwnership: '25',
        DateOfBirth: '1987-08-08',
        Address: {
          Unit: '11',
          Street: '11111 Test Road, 11',
          City: 'Richmond',
          Province: 'BC',
          PostalCode: 'V7V 2H9',
        },
        PublicInterest: 'false',
        PartnerIndividual_Seq: '415',
        Phone: '778-888-9999',
        Email: 'charliedoe@gmail.com',
      },
    },
    {
      CreatedBy: null,
      Status: 'P',
      ActivationDate: '',
      SubmittedDate: '',
      StartedReviewDate: '',
      DecisionDate: '',
      InactiveDate: '',
      ActivatedOnPlatform: false,
      PaymentAccountName: null,
      FeeAccountName: null,
      Language: 'en',
      IntegrationPlatform: null,
      PlatformPartner_Seq: '',
      Partner_Seq: '',
      PlatformPartnerName: 'Jupiter Company',
      LegalName: 'Jupiter Company Legal',
      BinNumber: '',
      GstHstNumber: '',
      LegalStructure: '',
      DateIncorporated: '',
      BusinessWebsiteUrl: '',
      Street: '',
      UnitSuite: '',
      City: '',
      Province: '',
      PostalCode: '',
      IndustryCategory: '',
      IndustrySubCategory: '',
      IsHighRisk: false,
      CountriesOfBusiness: [
        '',
      ],
      AverageTransactionValue: '0',
      MonthlySalesValue: '0',
      MonthlySalesVolume: '0',
      RegulatedEntity: '',
      SupportName: '',
      SupportPhone: '',
      OtherEmail: 'no-reply@starcompany.ca',
      SupportDateOfBirth: '',
      SupportAddress: null,
      BusinessOwnershipIndividuals: null,
      DisplayNameEn: 'Jupiter Company EN',
      DisplayNameFr: 'Jupiter Company FR',
      AgreementSignedDate: '',
      PayoutAccount: null,
      ClearingAccount: {
        Name: 'DirectPay Payment Clearing',
        AccountID: 'a6608de6-46fb-4719-92a7-18bd462dbfe5',
      },
      FeeAccount: {
        Name: 'DirectPay Payment Fee',
        AccountID: 'a6608de6-46fb-4719-92a7-18bd462dbfe5',
      },
      BankName: '',
      BankBranch: '',
      BankTransitNumber: '',
      AccountNumber: '',
      XeroData: {
        PayoutAccounts: [
          {
            Name: 'Business Account',
            ID: 'f63e2d2c-923e-47ee-9bc1-78a40a914856',
          },
        ],
        BrandingThemes: [
          {
            Name: 'Standard',
            ID: 'c4521403-696d-4c2c-b800-efc0c3ebb5a8',
            DirectPayEnabled: false,
          },
          {
            Name: 'Orange Theme',
            ID: '146576fb-9983-4b0d-8e78-e42b79409b9a',
            DirectPayEnabled: false,
          },
          {
            Name: 'Red Theme',
            ID: '481c61c7-f4d6-46fc-b139-609764ffde31',
            DirectPayEnabled: false,
          },
        ],
      },
      PrimaryIndividual: {
        FullName: 'Charlie Doe',
        PercentOwnership: '25',
        DateOfBirth: '1987-08-08',
        Address: {
          Unit: '11',
          Street: '11111 Test Road, 11',
          City: 'Richmond',
          Province: 'BC',
          PostalCode: 'V7V 2H9',
        },
        PublicInterest: 'false',
        PartnerIndividual_Seq: '415',
        Phone: '778-888-9999',
        Email: 'charliedoe@gmail.com',
      },
    },
  ];
