import Amplitude from 'amplitude-js';
import { isDevelopment } from './RuntimeEnv';

const AMPLITUDE_SESSION_TIMEOUT = 20 * 60 * 1000;
const ampLogStyle = 'background-color:aqua; color:black;';

const logToConsole = isDevelopment ? console.log : () => {}; // eslint-disable-line no-console

if (typeof window !== 'undefined') {
  Amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, null, {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
    sessionTimeout: AMPLITUDE_SESSION_TIMEOUT,
  });
  Amplitude.getInstance().setVersionName(process.env.NEXT_PUBLIC_VERSION);
}

export const getAmpSessionID = () => Amplitude.getInstance().getSessionId();
export const setAmpUser = (email) => {
  logToConsole(`%c[Amplitude]%c Setting user ID: ${email}`, ampLogStyle, '');
  Amplitude.getInstance().setUserId(email);
};
export const setUserProps = (properties) => {
  logToConsole('%c[Amplitude]%c Setting user property:', ampLogStyle, '', properties);
  Amplitude.getInstance().setUserProperties(properties);
};

export const logAmpEvent = (event, properties = undefined) => {
  logToConsole(
    `Amplitude event: %c${event}`,
    ampLogStyle,
    properties ? 'with properties:' : '',
    properties || ''
  );
  Amplitude.getInstance().logEvent(event, properties);
};

export const events = {
  // landing
  USER_VIEWED_MAIN_LANDING_PAGE: 'USER_VIEWED_MAIN_LANDING_PAGE',
  USER_CLICKED_LANDING_HERO_CTA_BUTTON: 'USER_CLICKED_LANDING_HERO_CTA_BUTTON',

  // navbar
  USER_CLICKED_NAVBAR_LINK: 'USER_CLICKED_NAVBAR_LINK',

  // footer
  USER_CHANGED_LANGUAGE: 'USER_CHANGED_LANGUAGE',
  USER_CLICKED_FOOTER_LINK: 'USER_CLICKED_FOOTER_LINK',
  USER_CLICKED_LANGUAGE_DROPDOWN: 'USER_CLICKED_LANGUAGE_DROPDOWN',

  // auth
  USER_CLICKED_GOOGLE_RECAPTCHA_PRIVACY_POLICY_LINK: 'USER_CLICKED_GOOGLE_RECAPTCHA_PRIVACY_POLICY_LINK',
  USER_CLICKED_GOOGLE_RECAPTCHA_TERMS_LINK: 'USER_CLICKED_GOOGLE_RECAPTCHA_TERMS_LINK',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  USER_REDIRECTED_TO_OAUTH_FLOW: 'USER_REDIRECTED_TO_OAUTH_FLOW',
  // confirm email
  USER_VIEWED_CONFIRM_EMAIL_PAGE: 'USER_VIEWED_CONFIRM_EMAIL_PAGE',
  USER_CLICKED_CHANGE_EMAIL_REGISTER: 'USER_CLICKED_CHANGE_EMAIL_REGISTER',
  USER_CLICKED_VERIFY_EMAIL_REGISTER: 'USER_CLICKED_VERIFY_EMAIL_REGISTER',
  USER_CLICKED_RESEND_CODE: 'USER_CLICKED_RESEND_CODE',
  // login
  USER_CLICKED_LOGIN_BUTTON: 'USER_CLICKED_LOGIN_BUTTON',
  USER_CLICKED_REGISTER_LINK_FROM_LOGIN: 'USER_CLICKED_REGISTER_LINK_FROM_LOGIN',
  USER_VIEWED_LOGIN_PAGE: 'USER_VIEWED_LOGIN_PAGE',
  USER_VIEWED_LOADING_SCREEN: 'USER_VIEWED_LOADING_SCREEN',
  USER_PROVIDED_SESSION_CODE_AND_EMAIL: 'USER_PROVIDED_SESSION_CODE_AND_EMAIL',
  USER_CLICKED_LEARN_MORE_ABOUT_DIRECTPAY_LINK: 'USER_CLICKED_LEARN_MORE_ABOUT_DIRECTPAY_LINK',
  // register
  USER_CLICKED_REGISTER_BUTTON: 'USER_CLICKED_REGISTER_BUTTON',
  USER_CLICKED_LOGIN_LINK_FROM_REGISTER: 'USER_CLICKED_LOGIN_LINK_FROM_REGISTER',
  USER_VIEWED_REGISTRATION_PAGE: 'USER_VIEWED_REGISTRATION_PAGE',
  // forgot password
  USER_VIEWED_FORGOT_PASSWORD_PAGE: 'USER_VIEWED_FORGOT_PASSWORD_PAGE',
  USER_CLICKED_SEND_CODE_FORGOTPASSWORD: 'USER_CLICKED_SEND_CODE_FORGOTPASSWORD',
  USER_CLICKED_UPDATE_PASSWORD: 'USER_CLICKED_UPDATE_PASSWORD',

  // applications
  USER_VIEWED_APPLICATIONS_PAGE: 'USER_VIEWED_APPLICATIONS_PAGE',
  USER_CLICKED_START_APPLICATION_BUTTON: 'USER_CLICKED_START_APPLICATION_BUTTON',
  USER_CLICKED_CONTINUE_APPLICATION_BUTTON: 'USER_CLICKED_CONTINUE_APPLICATION_BUTTON',
  USER_CLICKED_PLATFORM_SETTINGS_BUTTON: 'USER_CLICKED_PLATFORM_SETTINGS_BUTTON',
  USER_CLICKED_DISCONNECT_FROM_XERO_BUTTON: 'USER_CLICKED_DISCONNECT_FROM_XERO_BUTTON',
  USER_CLICKED_CONNECT_ANOTHER_BUTTON: 'USER_CLICKED_CONNECT_ANOTHER_BUTTON',
  USER_CLICKED_NO_PARTNERS_CONTACT_US_LINK: 'USER_CLICKED_NO_PARTNERS_CONTACT_US_LINK',
  USER_OPENED_APPLICATION_DROPDOWN: 'USER_OPENED_APPLICATION_DROPDOWN',
  USER_CLOSED_APPLICATION_DROPDOWN: 'USER_CLOSED_APPLICATION_DROPDOWN',
  USER_CLICKED_APPLICATION_READ_MORE_LINK: 'USER_CLICKED_APPLICATION_READ_MORE_LINK',
  USER_CLICKED_ACTIVATE_DIRECTPAY_LINK: 'USER_CLICKED_ACTIVATE_DIRECTPAY_LINK',
  USER_CLICKED_TURN_OFF_DIRECTPAY_BUTTON: 'USER_CLICKED_TURN_OFF_DIRECTPAY_BUTTON',
  USER_OPENED_DISCONNECT_FROM_XERO_POPUP: 'USER_OPENED_DISCONNECT_FROM_XERO_POPUP',
  USER_CLOSED_DISCONNECT_FROM_XERO_POPUP: 'USER_CLOSED_DISCONNECT_FROM_XERO_POPUP',
  USER_OPENED_TURN_OFF_DIRECTPAY_POPUP: 'USER_OPENED_TURN_OFF_DIRECTPAY_POPUP',
  USER_CLOSED_TURN_OFF_DIRECTPAY_POPUP: 'USER_CLOSED_TURN_OFF_DIRECTPAY_POPUP',
  USER_CLICKED_TURN_OFF_DIRECTPAY_LEARN_HOW_LINK: 'USER_CLICKED_TURN_OFF_DIRECTPAY_LEARN_HOW_LINK',
  USER_CLICKED_OTHER_INQUIRIES_LINK: 'USER_CLICKED_OTHER_INQUIRIES_LINK',
  USER_CLICKED_NEED_TO_CHANGE_PARNTER_ACCOUNT_INFO_LINK: 'USER_CLICKED_NEED_TO_CHANGE_PARNTER_ACCOUNT_INFO_LINK',

  // reports and refunds
  USER_CLICKED_BACK_BUTTON: 'USER_CLICKED_BACK_BUTTON',
  USER_CLICKED_REPORT_TAB: 'USER_CLICKED_REPORT_TAB',
  USER_VIEWED_REPORTS_PAGE: 'USER_VIEWED_REPORTS_PAGE',
  USER_CLICKED_REPORT_SEARCH_BUTTON: 'USER_CLICKED_REPORT_SEARCH_BUTTON',
  USER_CLICKED_REPORT_EXPORT_CSV: 'USER_CLICKED_REPORT_EXPORT_CSV',
  USER_CLICKED_SORT_COLUMN_BUTTON: 'USER_CLICKED_SORT_COLUMN_BUTTON',
  USER_FILTERED_REPORT_COLUMN: 'USER_FILTERED_REPORT_COLUMN',
  USER_OPENED_OPTIONS_DROPDOWN: 'USER_OPENED_OPTIONS_DROPDOWN',
  USER_CLOSED_OPTIONS_DROPDOWN: 'USER_CLOSED_OPTIONS_DROPDOWN',
  USER_OPENED_PAYMENT_DETAILS_POPUP: 'USER_OPENED_PAYMENT_DETAILS_POPUP',
  USER_CLOSED_PAYMENT_DETAILS_POPUP: 'USER_CLOSED_PAYMENT_DETAILS_POPUP',
  USER_CLICKED_OPTIONS_DROPDOWN_REQUEST_REFUND_BUTTON: 'USER_CLICKED_OPTIONS_DROPDOWN_REQUEST_REFUND_BUTTON',
  USER_VIEWED_SHOPIFY_REFUND_POPUP: 'USER_VIEWED_SHOPIFY_REFUND_POPUP',
  USER_CLOSED_SHOPIFY_REFUND_POPUP: 'USER_CLOSED_SHOPIFY_REFUND_POPUP',
  USER_CLICKED_SHOPIFY_REFUND_SEE_HOW_LINK: 'USER_CLICKED_SHOPIFY_REFUND_SEE_HOW_LINK',
  USER_CLICKED_REQUEST_REFUND_BUTTON: 'USER_CLICKED_REQUEST_REFUND_BUTTON',
  REPORT_FULLY_LOADED: 'REPORT_FULLY_LOADED',
  USER_CLICKED_VIEW_TRANSACTIONS_BUTTON: 'USER_CLICKED_VIEW_TRANSACTIONS_BUTTON',

  // select partner
  USER_SELECTED_PARTNER_TO_VIEW_REPORTS: 'USER_SELECTED_PARTNER_TO_VIEW_REPORTS',
  USER_VIEWED_SELECT_PARTNER_PAGE: 'USER_VIEWED_SELECT_PARTNER_PAGE',

  // before you start
  USER_VIEWED_BEFORE_YOU_START_APPLICATION_PAGE: 'USER_VIEWED_BEFORE_YOU_START_APPLICATION_PAGE',
  USER_CLICKED_READY_START_APPLICATION_BUTTON: 'USER_CLICKED_READY_START_APPLICATION_BUTTON',

  // application form pages
  USER_VIEWED_APPLICATION_BUSINESS_INFO_PAGE: 'USER_VIEWED_APPLICATION_BUSINESS_INFO_PAGE',
  USER_VIEWED_APPLICATION_CONTACT_INFO_PAGE: 'USER_VIEWED_APPLICATION_CONTACT_INFO_PAGE',
  USER_CLICKED_ADD_BUSINESS_OWNER_BUTTON: 'USER_CLICKED_ADD_BUSINESS_OWNER_BUTTON',
  USER_VIEWED_APPLICATION_PRICING_PAGE: 'USER_VIEWED_APPLICATION_PRICING_PAGE',
  USER_CLICKED_SELECT_PRICING_PLAN: 'USER_CLICKED_SELECT_PRICING_PLAN',
  USER_VIEWED_APPLICATION_AGREEMENT_PAGE: 'USER_VIEWED_APPLICATION_AGREEMENT_PAGE',
  USER_CLICKED_AGREE_TO_TERMS_BUTTON: 'USER_CLICKED_AGREE_TO_TERMS_BUTTON',
  USER_CLICKED_APPLICATION_BACK_BUTTON: 'USER_CLICKED_APPLICATION_BACK_BUTTON',
  USER_CLICKED_APPLICATION_SAVE_BUTTON: 'USER_CLICKED_APPLICATION_SAVE_BUTTON',
  USER_CLICKED_APPLICATION_NEXT_BUTTON: 'USER_CLICKED_APPLICATION_NEXT_BUTTON',
  USER_VIEWED_APPLICATION_PREFERENCES_PAGE: 'USER_VIEWED_APPLICATION_PREFERENCES_PAGE',
  USER_CLICKED_SETUP_FRENCH_DISPLAY_NAME: 'USER_CLICKED_SETUP_FRENCH_DISPLAY_NAME',
  USER_CLICKED_HIDE_FRENCH_DISPLAY_NAME: 'USER_CLICKED_HIDE_FRENCH_DISPLAY_NAME',
  USER_CLICKED_APPLICATION_SUBMIT_BUTTON: 'USER_CLICKED_APPLICATION_SUBMIT_BUTTON',
  USER_CLICKED_DELETE_BUSINESS_OWNER_BUTTON: 'USER_CLICKED_DELETE_BUSINESS_OWNER_BUTTON',
  USER_ENTERED_COMBINED_BUSINESS_OWNERSHIP_EXCEEDING_100_PERCENT: 'USER_ENTERED_COMBINED_BUSINESS_OWNERSHIP_EXCEEDING_100_PERCENT',
  USER_CLICKED_PRINT_OR_SAVE_PDF: 'USER_CLICKED_PRINT_OR_SAVE_PDF',
  USER_CLICKED_SELECT_FILE_BUTTON: 'USER_CLICKED_SELECT_FILE_BUTTON',
  USER_CLICKED_CHANGE_BUSINESS_REGISTRATION_FILE: 'USER_CLICKED_CHANGE_BUSINESS_REGISTRATION_FILE',
  USER_CLICKED_CHANGE_VOID_CHEQUE_FILE: 'USER_CLICKED_CHANGE_VOID_CHEQUE_FILE',
  USER_CLICKED_LEARN_MORE_ABOUT_INTERAC_NOTIFICATION_EMAILS: 'USER_CLICKED_LEARN_MORE_ABOUT_INTERAC_NOTIFICATION_EMAILS',

  // platform settings
  USER_VIEWED_PLATFORM_SETTINGS_SCREEN: 'USER_VIEWED_PLATFORM_SETTINGS_SCREEN',
  USER_CLICKED_PLATFORM_SETTINGS_CANCEL_BUTTON: 'USER_CLICKED_PLATFORM_SETTINGS_CANCEL_BUTTON',
  USER_CLICKED_PLATFORM_SETTINGS_SUBMIT_BUTTON: 'USER_CLICKED_PLATFORM_SETTINGS_SUBMIT_BUTTON',
  USER_CLICKED_BRANDING_THEMES_LINK: 'USER_CLICKED_BRANDING_THEMES_LINK',
  USER_CLICKED_BRANDING_THEMES_SEE_HOW_LINK: 'USER_CLICKED_BRANDING_THEMES_SEE_HOW_LINK',
  USER_CLICKED_CREATE_NEW_BRANDING_THEME_LINK: 'USER_CLICKED_CREATE_NEW_BRANDING_THEME_LINK',

  // maintenance
  USER_VIEWED_MAINTENANCE_PAGE: 'USER_VIEWED_MAINTENANCE_PAGE',
  USER_CLICKED_MAINTENANCE_ANIMATION_BUTTON: 'USER_CLICKED_MAINTENANCE_ANIMATION_BUTTON',

  // general
  API_ERROR: 'API_ERROR',
  API_SUCCESS: 'API_SUCCESS',
  API_MAINTENANCE_MODE: 'API_MAINTENANCE_MODE',
  GOOGLE_RECAPTCHA_ERROR: 'GOOGLE_RECAPTCHA_ERROR',
  USER_VIEWED_ERROR_PAGE: 'USER_VIEWED_ERROR_PAGE',
  USER_CLICKED_ERROR_PAGE_BUTTON: 'USER_CLICKED_ERROR_PAGE_BUTTON',
  USER_ENTERED_INVALID_VALUE: 'USER_ENTERED_INVALID_VALUE',
  USER_CLICKED_DATEPICKER_BUTTON: 'USER_CLICKED_DATEPICKER_BUTTON',
};
