import { setIntegrationPlatform } from '../../utils/IntegrationPlatform';
import { setUserGroup } from '../../utils/UserGroup';

export const types = {
  SET_EMAIL: 'SET_EMAIL',
  SET_SESSION: 'SET_SESSION',
  CLEAR_SESSION: 'CLEAR_SESSION',
};

export const setEmail = (email) => ({
  type: types.SET_EMAIL,
  payload: email,
});

/** @param {import('amazon-cognito-identity-js').CognitoUserSession} session */
export const setSession = (session) => {
  setIntegrationPlatform(session.getIdToken().payload['custom:integration_platform']);
  setUserGroup(session.getIdToken().payload['cognito:groups']);
  return {
    type: types.SET_SESSION,
    payload: session,
  };
};

export const clearSession = () => {
  setIntegrationPlatform('');
  return { type: types.CLEAR_SESSION };
};
