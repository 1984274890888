import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import { useRouter } from 'next/router';
import { captureException, captureMessage } from '@sentry/nextjs';
import { t, getLanguage } from './ReactSwitchLangWrapper';
import { setSession, clearSession } from '../redux/actions/AuthActions';
import { isDevelopment, isProduction } from './RuntimeEnv';
import { events, logAmpEvent } from './Amplitude';
import { AUTH_API, OAUTH_LINKS, PARTNER_API, REPORTS_API } from './Constants';

export const Pool = new CognitoUserPool({
  UserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
});

function log(...args) {
  // eslint-disable-next-line no-console
  if (isDevelopment) console.log(...args);
}

function warn(...args) {
  // eslint-disable-next-line no-console
  if (isDevelopment) console.warn(...args);
}

function cognitoLog(callName, data, success = true) {
  if (success) {
    log(`Cognito: %c${callName}`, 'background-color: plum;color:black;', data);
  } else {
    warn(`Cognito: %c${callName}`, 'background-color: plum;color:black;', data);
  }
}

function apiLog(callName, data) {
  log(`API: %c${callName}`, 'background-color: greenyellow;color:black;', data);
}

// custom error messages are returned in format "Error: error msg here"
const handleCognitoCustomError = (msg) => {
  const customErrorIdentifier = '[ERROR]';
  if (!msg) return false;
  if (msg.length < 6) return false;
  const customErrIndex = msg.indexOf(customErrorIdentifier);
  if (customErrIndex > -1) {
    return msg.substr(customErrIndex + customErrorIdentifier.length + 1);
  }
  return t('Error_Default');
};

function parseCognitoError(err, call) {
  // check language file for any custom error message overrides
  const langFileError = t(`Error_Cognito_${err.code}`);
  if (langFileError !== `Error_Cognito_${err.code}`) return langFileError;

  // special handling
  switch (err.code) {
    case 'NotAuthorizedException':
      if (call === AUTH_API.LOGIN) return t('Error_Cognito_IncorrectUsernamePassword');
      return t('Error_Cognito_NotAuthorized');
    case 'LimitExceededException':
    case 'TooManyFailedAttemptsException':
    case 'TooManyRequestsException':
      return t('Error_Cognito_Velocity');
    default:
      return handleCognitoCustomError(err.message);
  }
}

/**
 * Generates a cognito error handler callback that
 * takes in the error object from cognito and handles it accordingly by
 * calling either a custom handler or the generic handler.
 * The generic handler will parse the error object and
 * pass in the parsed error message into the errMsgCallback,
 * while also logging the API error event to Amplitude.
 *
 * @param {string} call name of the cognito call
 * @param {(parsedMessage:string)=>*} [errMsgCallback]
 * callback to run as part of the generic handler; will be passed in the parsed error message
 * @param {{ErrorCode:(error:{code:string;message:string;name:string})=>boolean}} [customHandlers]
 * key-value pairs for specific error handling,
 * where the key is the error code and the value is the custom handler for that error code
 * the return value of the handler indicates whether the error has been handled or not
 * @returns {(error:{code:string;message:string;name:string})=>*}
 */
export function genCognitoErrHandler(call, errMsgCallback, customHandlers) {
  return (err) => {
    let isHandled = false;
    if (customHandlers?.[err.code]) {
      // run custom handler
      isHandled = customHandlers[err.code](err);
    }

    if (isHandled) return false;

    // run generic handler
    const errMsg = parseCognitoError(err, call);
    logAmpEvent(events.API_ERROR, {
      Call: `Cognito: ${call}`,
      'Result Code': err.code,
      Description: errMsg,
    });
    errMsgCallback?.(errMsg);

    return false; // needed for ResendEmail component
  };
}

/**
 * A custom cognito error handler for the UserLambdaValidationException error code.
 * If the error message contains the string `[ExistingOAuthUser:platform]`,
 * redirects to the start of OAuth flow for the platform.
 * @param {{code:string;message:string;name:string}} err
 * @returns {boolean}
 */
export function ExistingOAuthUserHandler(err) {
  const oAuthPlatformToRedirectTo = err?.message?.match(/\[ExistingOAuthUser:(.*)\]/)?.[1];
  switch (oAuthPlatformToRedirectTo) {
    case 'Xero':
    case 'Shopify':
      logAmpEvent(events.USER_REDIRECTED_TO_OAUTH_FLOW, {
        Platform: oAuthPlatformToRedirectTo,
      });
      window.location.href = OAUTH_LINKS[oAuthPlatformToRedirectTo];
      return true;
    default: break;
  }
  return false;
}

// custom error handler for General api calls
export function handleAPIError(call, res, errMsgCallback) {
  if (res.Result === 0) return true; // successful
  errMsgCallback?.(res.Description || t('Error_Default'));
  return false;
}

// custom error handler for maintenance mode api calls
export function handleMaintenaceModeError(call, res, errMsgCallback) {
  if (res.Result === 0 || res.Result === 5) return true; // successful
  errMsgCallback?.(res.Description || t('Error_Default'));
  return false;
}

function callbackWrapper(callName, resolve, reject) {
  return (err, result) => {
    if (err) {
      cognitoLog(callName, err, false);
      reject(err);
    } else {
      cognitoLog(callName, result);
      resolve(result);
    }
  };
}

export async function getSessionAndAttr(lang) {
  return new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(callbackWrapper('getSession', (session) => {
        user.getUserAttributes(callbackWrapper('getUserAttributes', (attributes) => {
          // Update language user attribute and
          // Refresh session if language in user attributes does not match client language
          const currLang = attributes.find((attr) => attr.Name === 'custom:language')?.Value;
          if (currLang !== lang) {
            const customAttribute = new CognitoUserAttribute({
              Name: 'custom:language',
              Value: lang,
            });
            user.updateAttributes([customAttribute], callbackWrapper('updateAttributes', () => {
              user.refreshSession(session.getRefreshToken(), callbackWrapper('refreshSession', (sess) => {
                resolve([sess, attributes]);
              }, reject));
            }, reject));
          } else {
            resolve([session, attributes]);
          }
        }, reject));
      }, reject));
    } else {
      reject();
    }
  });
}

export function useCognito() {
  const [user, setUser] = useState(Pool.getCurrentUser());
  /** @type {import('amazon-cognito-identity-js').CognitoUserSession | null} */
  const session = useSelector((state) => state.auth.session);
  const dispatch = useDispatch();
  const router = useRouter();
  const lang = getLanguage();

  /** @returns {Promise<import('amazon-cognito-identity-js').CognitoUserSession>} */
  async function refreshSession() {
    return (new Promise((resolve, reject) => {
      user.refreshSession(session.getRefreshToken(), callbackWrapper('refreshSession', resolve, reject));
    })).then((sess) => {
      dispatch(setSession(sess));
      return sess;
    });
  }

  // General API Fetch Call
  async function makeCall(call, data = {}) {
    const reqBody = { ...data };
    const url = process.env.NEXT_PUBLIC_API_URI + call;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: session.getIdToken().getJwtToken(),
        'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
      },
      body: JSON.stringify(reqBody),
    }).then(async (res) => {
      const respBody = await res.json();

      apiLog(call, { url, reqBody, respBody, respStatus: res.status });

      // expired token
      if (res.status === 401 && respBody.message === 'The incoming token has expired') {
        return refreshSession().then((sess) => fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: sess.getIdToken().getJwtToken(),
            'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
          },
          body: JSON.stringify(reqBody),
        })).then(async (res2) => {
          const res2Body = await res2.json();
          apiLog(call, { url, reqBody, respBody: res2Body, respStatus: res2.status });
          return res2Body;
        });
      }

      if (res.status === 413) {
        return {
          Result: 413, // Request Too Long
          Description: t('Error_Default'),
        };
      }

      if (res.status !== 200) {
        const err = new Error(`${JSON.stringify(respBody)}`);
        err.name = res.status;
        throw err;
      }

      return respBody;
    }).catch((err) => {
      let mockResultCode = -1; // general fetch failures (network failures, etc)
      if (err) {
        if (err.message === 'Refresh Token has expired' || err.message === 'Refresh Token has been revoked') {
          mockResultCode = -2; // expired session, requires login again
          router.push(`/${lang}/logout`);
        } else if (isProduction) {
          if (err instanceof Error) captureException(err);
          else captureMessage(JSON.stringify(err));
        }
      }
      return {
        Result: mockResultCode,
        Description: t('Error_Default'),
      };
    });

    if (response.Result === 0) {
      logAmpEvent(events.API_SUCCESS, { Call: call });
    } else if (response.Result === 5) {
      if (call !== PARTNER_API.MAINTENANCE_MODE) router.push(`/${lang}/maintenance`);
      logAmpEvent(events.API_MAINTENANCE_MODE, {
        Call: `API: ${call}`,
        'Result Code': response.Result,
        Description: response.Description,
        MaintenanceMode: response.MaintenanceMode,
      });
    } else {
      logAmpEvent(events.API_ERROR, {
        Call: `API: ${call}`,
        'Result Code': response.Result,
        Description: response.Description,
      });
    }

    return response;
  }

  return {
    authenticate: async (Username, Password, ValidationData) => (new Promise((resolve, reject) => {
      window.Pace?.restart();

      const u = new CognitoUser({ Username: encodeURIComponent(Username), Pool });
      const authDetails = new AuthenticationDetails({
        Username: encodeURIComponent(Username),
        Password,
        ValidationData,
      });

      u.authenticateUser(authDetails, {
        onSuccess: (sess) => {
          cognitoLog('authenticateUser', sess);
          dispatch(setSession(sess));
          resolve(u);
        },
        onFailure: (err) => {
          cognitoLog('authenticateUser', err, false);
          reject(err);
        },
      });
      setUser(u);
    })),
    customAuth: async (Username, SessionCode) => (new Promise((resolve, reject) => {
      window.Pace?.restart();

      const u = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username });

      u.setAuthenticationFlowType('CUSTOM_AUTH');

      u.initiateAuth(authDetails, {
        onSuccess: (sess) => {
          cognitoLog('initiateAuth', sess);
          dispatch(setSession(sess));
          resolve(u);
        },
        onFailure: (err) => {
          cognitoLog('initiateAuth', err, false);
          reject(err);
        },
        customChallenge() {
          // User authentication depends on challenge response
          u.sendCustomChallengeAnswer(SessionCode, this);
        },
      });
      setUser(u);
    })),
    refreshSession,
    /**
     * Gets the value of the specified claim from the payload of the current session's id token.
     * The payload not only consists of all the user attribute values this app client can read,
     * but also the standard jwt claims such as iss, exp, and sub.
     * @param {string} attrName name of the cognito user attribute or claim;
     *  for custom attributes, prefix with `custom:`
     * @returns {string | number | undefined} value of the claim;
     *  if session or claim doesn't exist, returns undefined
     */
    getAttribute: (attrName) => session?.getIdToken().payload[attrName],
    /** @param {string} [redirectTo] path to redirect to after sign out (e.g. `/login`) */
    signOut: async (redirectTo) => (new Promise((resolve) => {
      window.Pace?.restart();
      if (!user) {
        dispatch(clearSession());
        resolve();
      } else {
        user.signOut(() => {
          dispatch(clearSession());
          setUser(null);
          if (redirectTo) router.push(`/${lang}${redirectTo}`);
          resolve();
        });
      }
    })),

    /**
     * Creates a CognitoUserAttribute object given the name and value
     * @param {string} name
     * attribute name as displayed on Cognito Console
     * ex) email, custom:reg_date, etc.
     * @param {string} value
     */
    attribute: (name, value) => new CognitoUserAttribute({ Name: name, Value: value }),

    // Signup
    /**
     * @param {string} email
     * @param {string} password
     * @param {array} userAttributes
     * @param {array} validationData
     */
    signUp: async (
      email,
      password,
      userAttributes,
      validationData
    ) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      Pool.signUp(encodeURIComponent(email), password, userAttributes, validationData, callbackWrapper('signUp', resolve, reject));
    })),

    // Confirm
    /**
     * @param {string} email email of the user confirming registration
     * @param {string} code verification code received by email
     */
    confirmRegistration: async (email, code) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      new CognitoUser({
        Username: encodeURIComponent(email),
        Pool,
      }).confirmRegistration(code, true, callbackWrapper('confirmRegistration', resolve, reject));
    })),
    resendConfirmationCode: async (email) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      new CognitoUser({
        Username: encodeURIComponent(email),
        Pool,
      }).resendConfirmationCode(callbackWrapper('resendConfirmationCode', resolve, reject));
    })),
    // forgot password flow
    resetPassword: async (email) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      new CognitoUser({
        Username: encodeURIComponent(email),
        Pool,
      }).forgotPassword({
        onSuccess: (data) => {
          cognitoLog('resetPassword', data);
          resolve(data);
        },
        onFailure: (err) => {
          cognitoLog('resetPassword', err, false);
          reject(err);
        },
      });
    })),
    confirmNewPassword: async (email, code, newPassword) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      new CognitoUser({
        Username: encodeURIComponent(email),
        Pool,
      }).confirmPassword(code, newPassword, {
        onSuccess: (data) => {
          cognitoLog('confirmNewPassword', data);
          resolve(data); // just returns 'SUCCESS'
        },
        onFailure: (err) => {
          cognitoLog('confirmNewPassword', err, false);
          reject(err);
        },
      });
    })),

    // Partner API Calls
    getPartners: async () => makeCall(PARTNER_API.GET_PARTNERS),
    updatePartner: async (SubmitApplication, Partner, VoidChequeStep = false) => makeCall(
      PARTNER_API.UPDATE_PARTNER,
      { SubmitApplication, Partner, VoidChequeStep }
    ).then((res) => (new Promise((resolve) => {
      if (!Partner?.Partner_Seq) {
        user.refreshSession(session.getRefreshToken(), (err, sess) => {
          if (err) {
            cognitoLog('refreshSession', err, false);
            resolve(res);
          } else {
            cognitoLog('refreshSession', sess);
            dispatch(setSession(sess));
            resolve(res);
          }
        });
      }
      resolve(res);
    }))),
    /* eslint-disable camelcase */
    updateSettings: async (
      PlatformPartner_Seq,
      PayoutAccount,
      BrandingThemes,
      ActivatedOnPlatform
    ) => makeCall(
      PARTNER_API.UPDATE_SETTINGS,
      {
        PlatformPartner_Seq,
        PayoutAccount,
        BrandingThemes,
        ActivatedOnPlatform,
      }
    ),
    disconnectPartner: async (PlatformPartner_Seq) => makeCall(
      PARTNER_API.DISCONNECT_PARTNERS,
      { PlatformPartner_Seq }
    ).then((res) => refreshSession().catch(() => {}).then(() => res)),
    checkMaintenanceMode: async () => makeCall(PARTNER_API.MAINTENANCE_MODE),
    shopifyActivate: async (PlatformPartner_Seq) => makeCall(
      PARTNER_API.SHOPIFY_ACTIVATE,
      { PlatformPartner_Seq }
    ),
    // Report API Calls
    getPaymentsReport: async (req) => makeCall(REPORTS_API.GET_PAYMENTS_REPORT, req),
    getLoadhubPaymentsReport: async (req) => makeCall(REPORTS_API.GET_LOADHUB_PAYMENTS_REPORT, req),
    getReconciliationReport: async (req) => makeCall(REPORTS_API.GET_RECONCILIATION_REPORT, req),
    getLoadhubReconciliationReport:
      async (req) => makeCall(REPORTS_API.GET_LOADHUB_RECONCILIATION_REPORT, req),
    getPayoutsReport: async (req) => makeCall(REPORTS_API.GET_PAYOUTS_REPORT, req),
    getPaymentDetails: async (PartnerPay_Seq) => makeCall(
      REPORTS_API.GET_PAYMENT_DETAILS,
      { PartnerPay_Seq }
    ),
    requestRefund: async (PartnerPay_Seq, Amount) => makeCall(
      REPORTS_API.REQUEST_REFUND,
      { PartnerPay_Seq, Amount }
    ),
  };
}
