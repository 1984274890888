import React from 'react';

import { translate, t } from '../utils/ReactSwitchLangWrapper';
import { moveFocusTo } from '../utils/Accessibility';
import style from '../styles/SkipToContent.module.scss';

function SkipToContent() {
  return (
    <div id={style.SkipTo}>
      <SkipToButton skipTo="main" scrollTop textKey="ButtonDescription_SkipToContent" />
      <SkipToButton skipTo="footer" textKey="ButtonDescription_SkipToFooter" />
    </div>
  );
}

function SkipToButton({ skipTo, textKey, scrollTop = false, ...args }) {
  return <div><button type="button" onClick={() => moveFocusTo(skipTo, scrollTop)} {...args}>{t(textKey)}</button></div>;
}

export default translate(SkipToContent);
